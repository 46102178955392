import { Icon, type IconName } from "package:/components/elements/Icon";
import type { OpeningTimesResource } from "@greentrails/api";
import type { LocaleKey } from "@greentrails/locales";
import type { ClassNameValue } from "tailwind-merge";
import { computed, defineComponent, effect, ref, useTranslations } from "#imports";
import style from "./DetailStatus.module.css";
import { parseOpeningTimes } from "./StatusUtils";
import { useNetwork } from "~/composables/useNetwork";

export const DetailStatus = defineComponent(
  (props: {
    class?: ClassNameValue;
    data: {
      state?: OpeningTimesResource;
      type?: string;
      trails?: unknown;
    };
  }) => {
    const t = useTranslations();
    const stateData = ref<OpeningTimesResource | undefined>(props.data?.state);
    const network = useNetwork();

    const isRound = "trails" in props.data || props.data.type === "round";

    const status = computed(() => {
      let state: string | undefined = undefined;
      let label = "";
      let icon = "";

      if (stateData.value) {
        const type = stateData.value?.type;
        if (type === "times" && stateData.value?.data) {
          state = parseOpeningTimes(
            stateData.value?.data,
            stateData.value?.states,
            false,
          ).state;
          // We display a different label for rounds in the restricted state.
          // In rounds it meas "Eingeschränkt" instead of "Gesperrt".
          label =
            t(`state.${state ?? "unknown"}${isRound ? ".round" : ""}` as LocaleKey) ?? "";
          icon = state ?? "unknown";
        } else {
          state = type;
          if (state === "custom") {
            label = stateData.value?.type_text ?? "";
            icon = stateData.value?.type_icon ?? "unknown";
          } else {
            label =
              t(`state.${state ?? "unknown"}${isRound ? ".round" : ""}` as LocaleKey) ??
              "";
            icon = state ?? "unknown";
          }
        }
      }

      if (!network.online) {
        return { state: undefined, label, icon };
      }

      return { state, label, icon };
    });

    effect(() => {
      stateData.value = props.data.state;
    });

    // console.info(props.data.slug, stateData.value);

    // TODO: enable polling
    // const apiClient = useApiClient();
    // let clearTimer: () => void;

    // const polling = () => {
    //   const t = setTimeout(
    //     async () => {
    //       if (props.type === DetailType.Round) {
    //         stateData.value = (await apiClient.round.roundsState(props.data.slug)).data;
    //       }
    //       if (props.type === DetailType.Trail) {
    //         stateData.value = (await apiClient.trail.trailsState(props.data.slug)).data;
    //       }
    //       if (props.type === DetailType.Poi) {
    //         stateData.value = (await apiClient.poi.poisState(props.data.slug)).data;
    //       }

    //       console.info(stateData.value);

    //       polling();
    //     },
    //     1000 * 60 * 15 /* 15 min */,
    //   );
    //   return () => {
    //     clearTimeout(t);
    //   };
    // };

    // onMounted(() => {
    //   if (props.type) clearTimer = polling();
    // });

    // onUnmounted(() => {
    //   clearTimer?.();
    // });

    return () => {
      return (
        <div
          class={[
            props.class,
            "inline-flex shrink-0 items-center whitespace-nowrap text-purple-400 text-xs uppercase",
            style["animation-shimmer"],
            status.value.state !== undefined ? style.complete : "",
          ]}
        >
          <Icon
            class="mr-2"
            name={`state-${status.value.icon}` as IconName}
            aria-hidden="true"
          />
          <span class="mt-[0.09em]">{status.value.label}</span>
        </div>
      );
    };
  },
  {
    name: "DetailStatus",
    props: ["class", "data"],
  },
);
