import { computed, defineComponent, ref } from "#imports";
import "@sv/elements/track";
import { PoiCard } from "package:/components/modules/PoiCard";
import type { PoiShortResource } from "@greentrails/api";
import { Loader } from "../elements/loader.jsx";
import { Slider } from "./Slider.jsx";
import { Tabs } from "./Tabs.jsx";

export const PoiTabSlider = defineComponent(
  (props: {
    pois?: PoiShortResource[] | null;
    categorized?: boolean;
    variant?: "light" | "dark";
    reducedHeight?: boolean;
  }) => {
    const activeTab = ref(0);

    const categories = computed(() => {
      return props.pois?.reduce((acc, poi) => {
        acc[poi.category.slug] = poi.category;
        return acc;
      }, {});
    });

    const orderedCategories = computed(() => {
      const cats = categories.value ?? {};
      return [...Object.keys(categories.value ?? {})].sort(
        (a, b) => cats[a].order - cats[b].order,
      );
    });

    const filteredPois = computed(() => {
      if (props.categorized) {
        const slug = orderedCategories.value[activeTab.value];
        return (
          props.pois?.filter((poi) => {
            return poi.category.slug === slug;
          }) || []
        );
      }

      return props.pois;
    });

    return () => (
      <div>
        {props.categorized && (
          <div class="pb-10">
            <Tabs
              labels={orderedCategories.value.map((category) => {
                return categories.value ? categories.value[category].title : "";
              })}
              active={activeTab.value}
              onChange={(i) => {
                activeTab.value = i;
              }}
            />
          </div>
        )}

        <div class="min-h-[360px]">
          <Slider variant={props.variant}>
            {filteredPois.value
              ? filteredPois.value.map((poi, i) => (
                  <div
                    class={[
                      "min-h-[25rem] flex-none pr-2 last:pr-0",
                      props.reducedHeight && "narrow:min-h-[13rem]",
                    ]}
                    key={`poi_${poi.slug}_${i}`}
                  >
                    <PoiCard
                      data={poi}
                      variant={props.variant}
                      reducedHeight={props.reducedHeight}
                    />
                  </div>
                ))
              : ""}
          </Slider>
        </div>
      </div>
    );
  },
  {
    name: "PoiTabSlider",
    props: ["pois", "categorized", "variant", "reducedHeight"],
  },
);
