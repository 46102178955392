import { useVisible } from "package:/composables/useVisible";
import { defineComponent, effect, onMounted, ref } from "vue";
import type { ClassNameValue } from "tailwind-merge";

export const Lazy = defineComponent(
  (props: { class?: ClassNameValue }, { slots }) => {
    const ele = ref<HTMLDivElement>();
    const visible = useVisible(ele);
    const loaded = ref(false);
    const active = ref(false);

    onMounted(() => {
      active.value = true;
    });

    effect(() => {
      if (visible.value && active.value) loaded.value = true;
    });

    return () => (
      <div class={["relative", props.class]} ref={ele}>
        {loaded.value && slots.default?.()}
      </div>
    );
  },
  {
    props: ["class"],
  },
);
