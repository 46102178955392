import { type Ref, onMounted, ref, watch } from "#imports";

export function useVisible(element: Ref<HTMLElement | undefined>) {
  const visible = ref(false);
  const observer = ref<IntersectionObserver>();

  onMounted(() => {
    observer.value = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          visible.value = entry.isIntersecting;
        }
      },
      {
        rootMargin: "360px",
        threshold: 0,
      },
    );
  });

  watch(element, () => {
    if (observer.value) {
      if (element.value) observer.value?.observe(element.value);
      return () => {
        if (element.value) observer.value?.unobserve(element.value);
      };
    }
    return;
  });

  return visible;
}
