import type { OpeningTimeResource, StateResource } from "@greentrails/api";
import { toZonedTime } from "date-fns-tz";
import { useTranslations } from "#imports";
import type { DetailType } from "~/utils";

export type FormattedOpeningTimes = {
  state: string;
  info: string;
  hint: string;
  days: Day[];
};

export type Day = {
  date: Date;
  dayOfWeek: number;
  state: string;
  hint: string;
  start: Date;
  end: Date;
  highlight: boolean;
  timeSlots: TimeSlot[];
};

export type TimeSlot = {
  start: Date;
  end: Date;
  state: string;
  hint: string;
  formattedOpeningHours: string;
  highlight: boolean;
};

export function parseOpeningTimes(
  openingTimes: OpeningTimeResource[],
  states: StateResource[] | null,
  info?: boolean,
  type?: DetailType,
): FormattedOpeningTimes {
  const t = useTranslations();

  //if openingTimes is not present return open state
  if (openingTimes.length === 0)
    return {
      state: "unknown",
      days: [],
      hint: "",
      info: "",
    };

  const result: FormattedOpeningTimes = {
    state: "closed",
    days: [],
    info: "",
    hint: "",
  };
  const timeZone = "Europe/Berlin";
  const days: Day[] = [];
  const today = toZonedTime(new Date(), timeZone);
  const nowWeekday = today.toLocaleDateString("de-DE", {
    weekday: "long",
  });
  const now = today.getTime();
  openingTimes.map((timeSlot) => {
    const date = toZonedTime(new Date(timeSlot.date), timeZone);
    const start = timeSlot.start
      ? toZonedTime(new Date(timeSlot.start as string), timeZone)
      : toZonedTime(new Date(timeSlot.date), timeZone);
    const end = timeSlot.end
      ? toZonedTime(new Date(timeSlot.end as string), timeZone)
      : toZonedTime(new Date(timeSlot.date).setHours(23, 59, 59, 999), timeZone);
    const day = date.toLocaleDateString("de-DE", {
      weekday: "long",
    });

    // highlight for current day
    days[day] = days[day] ?? {
      date: date,
      dayOfWeek: date.getDay() === 0 ? 7 : date.getDay(),
      state: undefined,
      highlight: day === nowWeekday,
      start: start,
      end: end,
      timeSlots: [],
    };

    // save the state and hint for the day (if one state is present use that state)
    days[day].state = timeSlot.state;
    days[day].hint = timeSlot.hint;

    // highlight for current time
    const highlight =
      day === nowWeekday ? start.getTime() <= now && end.getTime() >= now : false;

    // if current timeSlot is active use that state
    result.state = highlight ? timeSlot.state : result.state;

    const formattedStartHour = `${start.getHours().toString().padStart(2, "0")}:${start
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
    const formattedEndHour = `${end.getHours().toString().padStart(2, "0")}:${end
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
    const formattedOpeningHours = `${formattedStartHour} – ${formattedEndHour} ${t(
      "misc.clock",
    )}`;

    days[day].timeSlots.push({
      start: start,
      end: end,
      state: timeSlot.state,
      hint: timeSlot.hint,
      formattedOpeningHours: formattedOpeningHours,
      highlight: highlight,
    });
  });

  result.days = days;

  if (info) {
    result.hint = days[nowWeekday].hint;

    const nextDays: string[] = [];

    for (let i = 0; i < 7; i++) {
      const date = new Date();
      date.setDate(today.getDate() + i);
      nextDays.push(
        date.toLocaleDateString("de-DE", {
          weekday: "long",
        }),
      );
    }

    if (result.state === "restricted") {
      // check until when the restriction is active
      for (let i = 1; i < nextDays.length; i++) {
        const day = days[nextDays[i] as string] ?? {};
        if (day?.state !== "restricted") {
          const restrictionDay = day?.date.toLocaleDateString("de-DE", {
            weekday: "short",
          });
          const restrictionDate = day?.date.toLocaleDateString("de-DE");
          const restrictionTime = `${day?.start
            .getHours()
            .toString()
            .padStart(2, "0")}:${day?.start.getMinutes().toString().padStart(2, "0")}`;
          if (i === 1) {
            // today
            const restrictionEndTime = `${day?.end
              .getHours()
              .toString()
              .padStart(2, "0")}:${day?.end.getMinutes().toString().padStart(2, "0")}`;
            result.info =
              t("state.info.restrictionEndToday", {
                time: restrictionEndTime,
              }) ?? "";
          } else if (i === 2) {
            // tomorrow
            result.info =
              t("state.info.restrictionEndTomorrow", {
                time: restrictionTime,
              }) ?? "";
          } else {
            // other day
            result.info =
              t("state.info.restrictionEnd", {
                day: restrictionDay,
                date: restrictionDate,
                time: restrictionTime,
                state: t(`state.restricted${type === "round" ? ".round" : ""}`),
              }) ?? "";
          }
          break;
        }

        // restriction last longer than 7 days we should check state
        const state = states?.[0];
        if (result.info === "" && state?.end) {
          const date = toZonedTime(new Date(state.end), timeZone);
          const restrictionDay = date.toLocaleDateString("de-DE", {
            weekday: "short",
          });
          const restrictionDate = date.toLocaleDateString("de-DE");
          const restrictionTime = `${date.getHours().toString().padStart(2, "0")}:${date
            .getMinutes()
            .toString()
            .padStart(2, "0")}`;
          // today
          result.info =
            t("state.info.restrictionEnd", {
              day: restrictionDay,
              date: restrictionDate,
              time: restrictionTime,
              state: t(`state.restricted${type === "round" ? ".round" : ""}`),
            }) ?? "";
        }
      }
    } else {
      // check if upcoming days contain restriction
      for (let i = 1; i < nextDays.length; i++) {
        const day = days[nextDays[i] as string] ?? {};

        if (day?.state === "restricted") {
          const restrictionDay = day?.date.toLocaleDateString("de-DE", {
            weekday: "short",
          });
          const restrictionDate = day?.date.toLocaleDateString("de-DE");
          const restrictionTime = `${day?.start
            .getHours()
            .toString()
            .padStart(2, "0")}:${day?.start.getMinutes().toString().padStart(2, "0")}`;
          // @ts-ignore
          result.info =
            t("state.info.restrictionAnnounce", {
              day: restrictionDay,
              date: restrictionDate,
              time: restrictionTime,
              state: t(`state.restricted${type === "round" ? ".round" : ""}`),
            }) ?? "";
          break;
        }
      }
    }

    if (result.info !== "") {
      return result;
    }

    if (result.state === "open") {
      // get next closing time
      let end: Date | undefined = undefined;
      days[nowWeekday].timeSlots
        .filter((timeSlot) => timeSlot.end.getTime() > now)
        .map((timeSlot) => {
          if (!end) {
            end = timeSlot.end;
          } else if (
            timeSlot.end.getTime() > now &&
            timeSlot.end.getTime() - now <= end.getTime() - now
          ) {
            end = timeSlot.end;
          }
        });
      if (end) {
        result.info =
          t("state.info.closedFrom", {
            // @ts-ignore
            time: `${end.getHours().toString().padStart(2, "0")}:${end
              .getMinutes()
              .toString()
              .padStart(2, "0")}`,
          }) ?? "";
      }
    } else if (result.state === "closed") {
      // get next opening time
      let start: Date | undefined = undefined;
      let startDay = nowWeekday;

      // look if there is a time slot today
      start = days[nowWeekday].timeSlots.filter((timeSlot) => {
        return (
          timeSlot.start.getTime() > now &&
          timeSlot.state !== "closed" &&
          timeSlot.state !== "restricted"
        );
      })[0]?.start;

      // if there is no time slot today look for the next time slot
      if (!start) {
        nextDays.map((day) => {
          if (day === nowWeekday) return;
          if (days[day].state === "closed") return;
          if (days[day].state === "restricted") return;
          if (start) return;
          days[day].timeSlots.map((timeSlot) => {
            if (!start) {
              start = timeSlot.start;
              startDay = day;
            } else if (timeSlot.start.getHours() < start.getHours()) {
              start = timeSlot.start;
              startDay = day;
            }
          });
        });
      }

      // generate start info for today, tomorrow or a specific day
      if (start) {
        let translationKey: string;
        if (startDay === nowWeekday) {
          translationKey = "state.info.openToday";
        } else if (startDay === nextDays[nextDays.indexOf(nowWeekday) + 1]) {
          translationKey = "state.info.openTomorrow";
        } else {
          translationKey = "state.info.openAtDay";
        }

        result.info =
          t(translationKey as any, {
            // @ts-ignore
            time: `${start.getHours().toString().padStart(2, "0")}:${start
              .getMinutes()
              .toString()
              .padStart(2, "0")}`,
            day: startDay,
          }) ?? "";
      }

      // Closure last longer than 7 days we should check state
      const state = states?.[0];
      if (result.info === "" && state?.end) {
        const date = toZonedTime(new Date(state.end), timeZone);
        const restrictionDay = date.toLocaleDateString("de-DE", {
          weekday: "short",
        });
        const restrictionDate = date.toLocaleDateString("de-DE");
        const restrictionTime = `${date.getHours().toString().padStart(2, "0")}:${date
          .getMinutes()
          .toString()
          .padStart(2, "0")}`;
        // today
        result.info =
          t("state.info.restrictionEnd", {
            day: restrictionDay,
            date: restrictionDate,
            time: restrictionTime,
            state: t("state.closed"),
          }) ?? "";
      }
    }
  }
  return result;
}
