import { Button } from "package:/components/elements/Button";
import { Icon } from "package:/components/elements/Icon";
import type { ClassNameValue } from "tailwind-merge";
import { defineComponent, useFlags, useTranslations } from "#imports";

interface Props {
  id: string;
  class?: ClassNameValue;
  variant?: "iconDark" | "iconLight" | "smallLight" | "smallDark";
  label?: string;
}

export const BookmarkButton = defineComponent(
  (props: Props) => {
    const t = useTranslations();
    const flags = useFlags();

    return () => (
      <>
        {flags.bookmark && (
          <Button
            class={props.class}
            variant={props.variant ?? "iconDark"}
            label={props.label ? props.label : t("button.ariaLabelBookmark")}
          >
            <Icon name="star" aria-hidden="true" />
            {props.label && <span>{props.label}</span>}
          </Button>
        )}
      </>
    );
  },
  {
    name: "BookmarkButton",
    props: ["id", "class", "label", "variant"],
  },
);
