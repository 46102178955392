import { Icon } from "package:/components/elements/Icon";
import { Lazy } from "package:/components/elements/Lazy";
import { Link } from "package:/components/elements/Link";
import { BookmarkButton } from "package:/components/modules/BookmarkButton";
import { RouteSketch } from "package:/components/modules/RouteSketch";
import { DetailStatus } from "package:/components/modules/Status/DetailStatus";
import { DetailType, ROUTE_VIEW, distanceToString } from "package:/utils";
import type { RoundShortResource, TrailShortResource } from "@greentrails/api";
import { twMerge } from "tailwind-merge";
import { defineComponent, useLanguage, useTranslations } from "#imports";

function getDetailType(detail: RoundShortResource | TrailShortResource) {
  switch (detail.type) {
    case "poi":
      return DetailType.Poi;
    case "trail":
    case "themetrail":
    case "trailsection":
    case "connection":
      return DetailType.Trail;
    case "round":
      return DetailType.Round;
    case "area":
      return DetailType.Area;
  }
  throw new Error(`type not defined '${detail.type}'`);
}

const variants = {
  light: {
    base: "bg-white text-green-800",
    gradient: "after:bg-gradient-white",
  },
  dark: {
    base: "bg-green-800 text-white",
    gradient: "after:bg-gradient-green-800",
  },
  mediumDark: {
    base: "bg-green-500 text-white",
    gradient: "after:bg-gradient-green-500",
  },
};

const titleOverlayClasses =
  "relative after:content-[''] after:absolute after:bg-gradient-green-900 after:right-0 after:w-20 after:block after:h-full after:top-0 after:pointer-events-none;";

interface Props {
  detail: RoundShortResource | TrailShortResource;
  areaId: string;
  variant: keyof typeof variants;
}

export const RouteCard = defineComponent(
  (props: Props) => {
    const t = useTranslations();
    const lang = useLanguage();

    return () => (
      <article
        class={twMerge(
          "focus-within relative w-[var(--width-card)] max-w-[calc(100vw-2.5rem)] rounded p-5",
          variants[props.variant ?? "light"].base,
        )}
      >
        <header>
          <DetailStatus data={props.detail} class="mb-3" />
          <Link
            class="before:absolute before:inset-0 before:z-1 before:cursor-pointer before:content-['']"
            to={{
              name: "lang-map-area-type-detail",
              params: {
                lang: lang.value,
                area: props.areaId,
                type: getDetailType(props.detail),
                detail: props.detail.slug,
              },
              query: {
                view: ROUTE_VIEW.DETAIL,
              },
            }}
          >
            <h3
              class={twMerge(
                titleOverlayClasses,
                variants[props.variant ?? "light"].gradient,
                "w-[17.25rem] overflow-hidden whitespace-nowrap text-xl",
              )}
            >
              {props.detail.title}
            </h3>
          </Link>
          <p class="font-light">
            {props.detail.type ? t(`misc.${props.detail.type}` as any) : t("misc.round")}
            {props.detail.short && (
              <span
                class="ml-2 inline-flex items-center rounded-full bg-green-500 px-2 py-0.5"
                style={{ color: props.detail.color }}
              >
                <Icon
                  name="trail"
                  class="mr-1 inline-block text-[0.55em]"
                  aria-hidden="true"
                />
                <span class="text-xs">
                  {/* @ts-ignore */}
                  {props.detail.short}
                </span>
              </span>
            )}
          </p>
          <BookmarkButton
            id={props.detail.slug}
            class="absolute top-5 right-5 z-1 h-8 w-8"
            variant={props.variant !== "light" ? "iconDark" : "iconLight"}
          />
        </header>
        <div class="mt-4 flex">
          <div class="h-[5.875rem] w-[8.75rem] pr-2">
            <Lazy class="h-full w-full">
              <RouteSketch
                class="h-full w-full"
                slug={props.detail.slug}
                type={props.detail.type}
                variant={props.variant}
              />
            </Lazy>
          </div>
          <div class="flex flex-1 flex-wrap gap-y-5">
            {props.detail.distance && (
              <div class="w-1/2">
                <p
                  class={twMerge(
                    "text-xs uppercase",
                    props.variant !== "light" ? "text-green-200" : "text-green-800",
                  )}
                >
                  {t("misc.distance")}
                </p>
                <p class="whitespace-nowrap font-light">
                  {distanceToString(props.detail.distance)}
                </p>
              </div>
            )}
            {/* @ts-ignore */}
            <div class="w-1/2">
              {props.detail.duration && (
                <>
                  <p
                    class={twMerge(
                      "text-xs uppercase",
                      props.variant !== "light" ? "text-green-200" : "text-green-800",
                    )}
                  >
                    {t("misc.duration")}
                  </p>
                  <p class="whitespace-nowrap font-light">{props.detail.duration}</p>
                </>
              )}
            </div>
            {props.detail.elevation_gain !== null && (
              <div class="w-1/2">
                <p
                  class={twMerge(
                    "text-xs uppercase",
                    props.variant !== "light" ? "text-green-200" : "text-green-800",
                  )}
                >
                  {t("misc.elevationGain")}
                </p>
                <p class="whitespace-nowrap font-light">
                  <Icon
                    name="arrow-gain"
                    class="mt-[-0.8em] mr-2 inline-block align-middle text-[0.5rem]"
                    aria-hidden="true"
                  />
                  <span>{distanceToString(props.detail.elevation_gain, true)}</span>
                </p>
              </div>
            )}
            {props.detail.elevation_loss !== null && (
              <div class="w-1/2">
                <p
                  class={twMerge(
                    "text-xs uppercase",
                    props.variant !== "light" ? "text-green-200" : "text-green-800",
                  )}
                >
                  {t("misc.elevationLoss")}
                </p>
                <p class="whitespace-nowrap font-light">
                  <Icon
                    name="arrow-loss"
                    class="mt-[-0.8em] mr-2 inline-block align-middle text-[0.5rem]"
                    aria-hidden="true"
                  />
                  <span>{distanceToString(props.detail.elevation_loss, true)}</span>
                </p>
              </div>
            )}
          </div>
        </div>
      </article>
    );
  },
  {
    name: "RouteCard",
    props: ["detail", "areaId", "variant"],
  },
);
