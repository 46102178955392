import * as Comlink from "comlink";

// one worker per key
const workerPool = {};

function getWorker(key: string, workerConstructor, options) {
  // TODO: manage worker pool
  if (!workerPool[key]) {
    workerPool[key] = Comlink.wrap(new workerConstructor(options));
  }
  return workerPool[key];
}

export function useWorker<T = any>(key: string, workerConstructor?: any, options?: any) {
  return getWorker(key, workerConstructor, options) as Comlink.Remote<T>;
}
