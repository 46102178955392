import { Dynamic } from "package:/components/elements/Dynamic";
import { useAsync } from "package:/composables/useAsync";
import { useWorker } from "package:/composables/useWorker";
import { warn } from "package:/utils";
import SVGWorker from "package:/worker/geoJsonToSVG?worker";
import { defineComponent, onMounted, ref, useEnv } from "#imports";

export function useGeoJsonToSVGWorker() {
  return useWorker<typeof import("package:/worker/geoJsonToSVG").geoJsonToSVG>(
    "geoJsonToSVG",
    SVGWorker,
  );
}

export const RouteSketch = defineComponent(
  (props: {
    slug: string;
    type: string;
    variant?: "dark" | "light" | "mediumDark";
    marker?: "simple" | "extended";
    highlightSlug?: string;
  }) => {
    const env = useEnv();
    const marker = props.marker ?? "extended";
    const svg = ref<SVGImageElement>();
    const markerScale: number = 1;
    const markerSize: number = 16 * markerScale;
    const svgPadding: number = markerSize * 0.8 + (marker === "simple" ? 0 : 0);
    const viewBoxWidth: number = 132;
    const viewBoxHeight: number = 94;
    const viewBox = `-${svgPadding / 2} -${svgPadding / 2} ${viewBoxWidth + svgPadding} ${
      viewBoxHeight + svgPadding
    }`;

    const worker = useGeoJsonToSVGWorker();
    const data = useAsync(async () => {
      return await worker({
        baseUrl: `${env.API_URL}/api`,
        type: props.type,
        slug: props.slug,
        variant: props.variant,
        viewBoxHeight,
        viewBoxWidth,
      });
    }, `${props.type}_${props.slug}_${props.variant}`);

    const renderedSvg = ref<string>();

    onMounted(async () => {
      const { data: svgString } = await data.suspense();
      renderedSvg.value = svgString;

      requestAnimationFrame(() => {
        if (svg.value) {
          // set calculated viewBox
          drawMarker(svg.value, {
            scale: markerScale,
            highlightSlug: props.highlightSlug,
            marker: marker,
            type: props.type,
            variant: props.variant,
            viewBoxHeight: viewBoxHeight,
            viewBoxWidth: viewBoxWidth,
          });
        } else {
          warn("svg not defined");
        }
      });
    });

    return () => (
      <figure>
        <Dynamic data={data}>
          <svg
            class="h-full w-full"
            viewBox={viewBox}
            v-html={renderedSvg.value}
            ref={svg}
          />
        </Dynamic>
      </figure>
    );
  },
  {
    name: "RouteSketch",
    props: ["slug", "type", "variant", "marker", "highlightSlug"],
  },
);

function addMarker(svg: SVGImageElement, point: DOMPoint, marker, scale) {
  svg.innerHTML += `<g style="transform:translate(${Number(point.x) - 8}px, ${
    Number(point.y) - 8
  }px) scale(${scale}); transform-origin: 8px 8px;">${marker}</g>`;
}

function drawMarker(
  svg: SVGImageElement,
  { variant, scale, marker, type, highlightSlug, viewBoxWidth, viewBoxHeight },
) {
  const fillColor =
    variant === "dark" ? "#002417" : variant === "mediumDark" ? "#003723" : "white";

  const circleMarkerSmall = `<ellipse fill="${fillColor}" cx="8" cy="8" ry="5" rx="5"></ellipse><ellipse fill="currentColor" cx="8" cy="8" ry="2" rx="2"></ellipse>`;
  // const circleMarkerLarge = `<ellipse fill="${fillColor}" cx="8" cy="8" ry="10" rx="10"></ellipse><path  vector-effect="non-scaling-stroke" fill-rule="${fillColor}" clip-rule="evenodd" d="M1.6 8C1.6 11.5346 4.46538 14.4 8 14.4C11.5346 14.4 14.4 11.5346 14.4 8C14.4 4.46538 11.5346 1.6 8 1.6C4.46538 1.6 1.6 4.46538 1.6 8ZM8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16Z" fill="currentColor"/><path vector-effect="non-scaling-stroke" d="M8 9.6C8.88366 9.6 9.6 8.88366 9.6 8C9.6 7.11634 8.88366 6.4 8 6.4C7.11634 6.4 6.4 7.11634 6.4 8C6.4 8.88366 7.11634 9.6 8 9.6Z" fill="currentColor"/>`;
  const arrowMarker = `<ellipse fill="${fillColor}" cx="8" cy="8" ry="10" rx="10"></ellipse><path vector-effect="non-scaling-stroke" fill-rule="evenodd" clip-rule="evenodd" d="M1.6 8C1.6 11.5346 4.46538 14.4 8 14.4C11.5346 14.4 14.4 11.5346 14.4 8C14.4 4.46538 11.5346 1.6 8 1.6C4.46538 1.6 1.6 4.46538 1.6 8ZM8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16Z" fill="currentColor"/><path vector-effect="non-scaling-stroke" d="M7.45553 4.26857C7.76801 3.95622 8.27454 3.95632 8.5869 4.26881L11.7599 7.44316C11.7866 7.46929 11.8114 7.49728 11.8342 7.5269C11.86 7.5603 11.8827 7.59526 11.9025 7.63143C11.9373 7.69513 11.9637 7.7641 11.9802 7.83682C12.0416 8.09763 11.9706 8.38329 11.7672 8.58668L8.56725 11.7867C8.25483 12.0991 7.7483 12.0991 7.43588 11.7867C7.12346 11.4743 7.12346 10.9677 7.43588 10.6553L9.27664 8.81455H4.8C4.35817 8.81455 4 8.45638 4 8.01455C4 7.57272 4.35817 7.21455 4.8 7.21455H9.26914L7.45529 5.39994C7.14294 5.08746 7.14304 4.58093 7.45553 4.26857Z" fill="currentColor"/>`;
  const startMarker = marker === "simple" ? circleMarkerSmall : arrowMarker;
  const endMarker = circleMarkerSmall;

  const paths = svg.querySelectorAll("path");
  if (paths && paths?.length > 0) {
    if (!highlightSlug) {
      // only set section markers for rounds
      if (type === "round") {
        // set marker section markers
        for (let i = 1; i < paths.length - 1; i++) {
          const path = paths[i];
          const pathLength = paths[i]?.getTotalLength();
          if (path && pathLength) {
            const point = path.getPointAtLength((100 / 100) * pathLength);
            addMarker(svg, point, circleMarkerSmall, scale);
          }
        }
      }

      // end marker
      const endPath = paths[paths.length - 1];
      if (endPath) {
        const pathLength = endPath.getTotalLength();
        const point = endPath.getPointAtLength((100 / 100) * pathLength);
        addMarker(svg, point, endMarker, scale);
      }

      // start marker
      const startPath = paths[0];
      if (startPath) {
        const pathLength = startPath.getTotalLength();
        const point1 = startPath.getPointAtLength((0 / 100) * pathLength);
        const point2 = startPath.getPointAtLength((1 / 100) * pathLength);
        const dx = point2.x - point1.x;
        const dy = point2.y - point1.y;
        const angleRadians = Math.atan2(dy, dx);
        const angleDegrees = angleRadians * (180 / Math.PI);
        svg.innerHTML += `<g style="transform:translate(${Number(point1.x) - 8}px, ${
          Number(point1.y) - 8
        }px) scale(${scale}) rotate(${angleDegrees}deg); transform-origin: 8px 8px;">${startMarker}</g>`;
      }
    } else {
      const highlightPath = svg.querySelector<SVGPathElement>(`[id="${highlightSlug}"]`);
      if (highlightPath) {
        const pathLength = highlightPath.getTotalLength();

        highlightPath.setAttribute("stroke", "currentColor");
        highlightPath.setAttribute("stroke-width", "1.4");
        svg.insertBefore(highlightPath, svg.querySelector("g"));

        const pointStart = highlightPath.getPointAtLength((0 / 100) * pathLength);
        addMarker(svg, pointStart, circleMarkerSmall, scale);

        const point = highlightPath.getPointAtLength((100 / 100) * pathLength);
        addMarker(svg, point, circleMarkerSmall, scale);
      }
    }

    svg.innerHTML = `<g>${svg.innerHTML}</g>`;

    const group = svg.querySelector("g");
    if (group) {
      const bbox = group.getBBox();
      const width = bbox.width;
      const height = bbox.height;

      const xOffset = (viewBoxWidth - width) / 2 - bbox.x;
      const yOffset = (viewBoxHeight - height) / 2 - bbox.y;

      group.setAttribute("transform", `translate(${xOffset} ${yOffset})`);
    }
  }
}
