import { log } from "package:/utils";
import { useQuery } from "@tanstack/vue-query";
import { effect } from "vue";

/**
 * Composable to fetch api data.
 */
export function useAsync<T>(callback: () => Promise<T>, cacheKey?: string) {
  const { isPending, data, error, suspense } = useQuery<T>({
    staleTime: process.browser ? 1000 * 60 : 0,
    gcTime: process.browser ? 1000 * 60 * 60 : 0,
    refetchOnReconnect: false,
    networkMode: "always",
    retry() {
      return false;
    },
    queryKey: [cacheKey, callback.toString()],
    queryFn: () => callback(),
  });

  effect(() => {
    if (error.value) {
      log.error(error.value.message);
    }
  });

  return {
    pending: isPending,
    error: error,
    data: data,
    suspense: suspense,
  };
}
