import { Icon, type IconName } from "package:/components/elements/Icon";
import { Link } from "package:/components/elements/Link";
import { Picture } from "package:/components/elements/Picture";
import { DetailStatus } from "package:/components/modules/Status/DetailStatus";
import { DetailType, ROUTE_VIEW } from "package:/utils";
import type { OpeningTimesResource, PoiCategoryResource } from "@greentrails/api";
import { twMerge } from "tailwind-merge";
import { defineComponent, useLanguage } from "#imports";

const variants = {
  light: "bg-white text-green-800",
  dark: "bg-green-500 text-white",
};

const titleOverlayClasses =
  "relative after:content-[''] after:absolute after:bg-gradient-green-800 after:right-0 after:w-24 after:block after:h-7 after:top-[3.6rem] after:pointer-events-none after:-translate-y-full";
const copyOverlayClasses = twMerge(titleOverlayClasses, "after:top-[4.8rem]");

export const PoiCard = defineComponent(
  (props: {
    variant?: keyof typeof variants;
    reducedHeight?: boolean;
    data: {
      slug: string;
      area: {
        slug: string;
      };
      title: string;
      abstract: string;
      category: PoiCategoryResource;
      images: {
        url: string;
        alt: string;
      }[];
      state: OpeningTimesResource;
    };
  }) => {
    const lang = useLanguage();

    const imageCount = props.data.images.length;

    return () => (
      <article
        class={twMerge(
          variants[props.variant ?? "light"],
          "focus-within relative block h-full w-[var(--width-card)] overflow-hidden rounded",
        )}
      >
        <div
          class={["relative block aspect-2_1", props.reducedHeight && "narrow:hidden"]}
        >
          {imageCount > 0 && (
            <Picture
              lazy
              src={props.data.images[0]?.url}
              alt={props.data.images[0]?.alt}
              width={350}
              height={170}
              sizes="sm:350px"
              imgClass="w-full h-full"
            />
          )}

          {imageCount > 1 && (
            <div class="absolute right-3 bottom-3 h-[5rem] w-[5rem] overflow-hidden rounded border border-white border-width-1 text-white">
              <Picture
                class="after:absolute after:top-0 after:left-0 after:h-full after:w-full after:bg-black after:opacity-50 after:content-['']"
                lazy
                src={props.data.images[1]?.url}
                alt={props.data.images[1]?.alt}
                width={80}
                height={80}
                sizes="sm:80px"
                imgClass="w-full h-full"
                imgAttrs={{
                  ariaHidden: true,
                }}
              />
              <span class="absolute top-1 right-2 text-xs">{`+${imageCount - 1}`}</span>
            </div>
          )}
          {imageCount === 0 && (
            <div
              class={twMerge(
                "flex items-center justify-center text-[6.875rem]",
                props.variant === "light"
                  ? "border-color-gray border-b-2 bg-white text-gray"
                  : "border-color-green-900 border-b-2 text-green-800",
              )}
            >
              <Icon name={`poi-big-${props.data.category?.icon}` as IconName} />
            </div>
          )}
        </div>
        <div class={["h-[14.5rem] p-5"]}>
          <DetailStatus
            class="mb-3"
            data={{
              state: props.data.state,
            }}
          />
          <Link
            class="before:absolute before:inset-0 before:z-1 before:cursor-pointer before:content-['']"
            to={{
              name: "lang-map-area-type-detail",
              params: {
                lang: lang.value,
                area: props.data.area.slug,
                type: DetailType.Poi,
                detail: props.data.slug,
              },
              query: {
                view: ROUTE_VIEW.DETAIL,
              },
            }}
          >
            <h3
              class={twMerge(
                titleOverlayClasses,
                props.variant === "dark"
                  ? "after:bg-gradient-green-500"
                  : "after:bg-gradient-white",
                "mb-6 max-h-[3.6rem] overflow-hidden text-xl",
              )}
            >
              {props.data.title}
            </h3>
          </Link>
          <p
            class={twMerge(
              copyOverlayClasses,
              props.variant === "dark"
                ? "after:bg-gradient-green-500"
                : "after:bg-gradient-white",
              "max-h-[4.8rem] overflow-hidden text-base",
            )}
          >
            {props.data.abstract}
          </p>
        </div>
      </article>
    );
  },
  {
    name: "PoiCard",
    props: ["variant", "reducedHeight", "data"],
  },
);
